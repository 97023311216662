/* Grid System by Gustavo Barbosa */
@for $i from 1 through 12 {
  .row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
  }
  &.col-#{$i} {
    width: calc(100% / 12 * #{$i});
    flex: 0 0 calc(100% / 12 * #{$i});
    padding: 5px 5px;
    @media (max-width: 991px) {
      width: 100%;
      flex: 100%;
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: var(--ion-color-secondary);
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--ion-color-secondary-shade);
}
::-webkit-scrollbar-thumb:active {
  background: var(--ion-color-primary);
}
::-webkit-scrollbar-track {
  background: #eaeaea;
}
::-webkit-scrollbar-track:hover {
  background: #eaeaea;
}
::-webkit-scrollbar-track:active {
  background: #eaeaea;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
.App {
  overflow: hidden;
}

.schema {
  display: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  letter-spacing: -0.025em;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
img {
  display: block;
}
body {
  line-height: 1;
  background-color: var(--ion-background-color);
}
a {
  text-decoration: none;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
b,
strong {
  font-weight: 600;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.clear {
  clear: both;
}

body {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  letter-spacing: -0.025em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--ion-text-color);
}

button,
input,
textarea {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  letter-spacing: -0.025em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  outline: none !important;
  box-sizing: border-box;
}

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #F5C719 ;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-shade: #d4a90d;
  --ion-color-primary-tint: #FFEFB2;

  /** secondary **/
  --ion-color-secondary: #faeeea;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-shade: #dbc6be;
  --ion-color-secondary-tint: #fff5f2;

  /** tertiary **/
  --ion-color-tertiary: #351f5e;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-shade: #241344;
  --ion-color-tertiary-tint: #53348d;

  /** success **/
  --ion-color-success: #62c235;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-shade: #41a016;
  --ion-color-success-tint: #90e768;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f7f6f3;
  --ion-color-light-contrast: #000000;
  --ion-color-light-shade: #e4e1d7;
  --ion-color-light-tint: #fafafa;

  --ion-background-color: #fff;

  --ion-text-color: #71727f;

  --ion-text-dark: #222427;

  --ion-border-color: #e7e6e4;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #1e1e1e;
}

button {
  &:disabled {
    opacity: 0.5 !important;
  }
}

.hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 10px;
  width: 10px;
  overflow: hidden;
}

.inactive {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

#modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.3);
  transition: 0.5s;
  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .content {
    width: 500px;
    max-width: 90%;
    background-color: white;
    padding: 50px;
    border-radius: 5px;
    text-align: center;
    h3 {
      font-size: 32px;
      font-weight: bold;
      color: var(--ion-text-color);
      margin-bottom: 15px;
    }

    p {
      font-size: 16px;
      color: var(--ion-text-color);
      font-weight: 500;
      margin-bottom: 50px;
    }

    button {
      width: 100%;
      height: 44px;
      background: var(--ion-color-primary);
      border-radius: 10px;
      display: block;
      margin: 0 auto;
      color: white;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.container {
  display: block;
  width: 100%;
  max-width: 1250px;
  padding: 0 1rem;
  margin: 0 auto;
}

p {
  color: var(--ion-text-color);
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 15px;
}

.heading-title {
  font-size: 42px;
  color: var(--ion-color-primary);
}

ul.bread {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: var(--ion-color-primary);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 90px;
  gap: 40px;

  li {
    position: relative;
    &::before {
      content: ">";
      position: relative;
      left: -20px;
    }

    &:first-child {
      &::before {
        display: none;
      }
    }
    a {
      color: var(--ion-color-primary);
    }
  }
}

.produtos {
  .list {
    > div {
      a {
        width: 100%;
        max-width: 300px;
        display: block;
        margin: 0 auto;
        padding: 10px;
        .imagem {
          width: 100%;
          height: 280px;
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
          border: 1px solid #d5d5d5;
        }

        p {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 14px;
          text-align: center;
          letter-spacing: -0.025em;
          margin: 20px 0;
          color: #495057;
        }

        button {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 100%;
          text-align: center;
          letter-spacing: -0.025em;
          color: #ffffff;
          background-color: var(--ion-color-primary);
          border: 0;
          width: 100%;
          height: 38px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .container {
    max-width: unset;
  }

  ul.bread {
    display: none;
  }

  .heading-title {
    font-size: 28px;
    line-height: 36px;
  }
}

.fade-appear,
.fade-enter {
  opacity: 0;
}

.fade-appear-active,
.fade-enter-active {
  transition: opacity 0.3s linear;
  opacity: 1;
}

.fade-exit {
  transition: opacity 0.2s linear;
  opacity: 1;
  position: fixed;
  top: 100px;
  left: -3px;
  width: 100vw;
}

.fade-exit-active {
  opacity: 0;
}

.suspense {
  width: 100vw;
  height: 100vh;
}
a.floating-whatsapp {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  background-color: #25d366;
  border-radius: 50%;
  padding: 5px 5px 5px 7px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
  animation: whatsapp 3.5s infinite;
  img {
    width: 48px;
    height: 48px;
  }

  &::before {
    content: "fale conosco";
    position: absolute;
    width: 150px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -160px;
    font-size: 16px;
    top: 15px;
    background-color: #efefef;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: #2c4760;
    border-radius: 5px;
    font-weight: 500;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
}


@keyframes whatsapp {
  0% {
    transform: scale(1);
  }
  35% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.1) rotate(-25deg);
  }
  45% {
    transform: scale(1.1) rotate(25deg);
  }
  50% {
    transform: scale(1.1) rotate(-25deg);
  }
  55% {
    transform: scale(1.1) rotate(25deg);
  }
  60% {
    transform: scale(1.1);
  }
  65% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}