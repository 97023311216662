#footer {
    background-color: #1a1a1a;
    padding: 96px 0 30px;

    .menus {
        .container {
            .row {
                > div {
                    > a {
                        display: block;
                        margin-bottom: 32px;
                        > img {
                            width: 223px;
                            height: 57px;
                        }
                    }

                    .socials {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 8px;
                        a {
                            img {
                                width: 30px;
                                height: 30px;
                            }
                        }
                    }

                    .bndes {
                        img {
                            width: 140px;
                            height: 30px;
                            margin: 50px 0 25px;
                        }

                        > a {
                            background: white;
                            padding: 12px 16px;
                            text-align: center;
                            border-radius: 6px;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 14px;
                            /* identical to box height, or 100% */

                            letter-spacing: -0.025em;

                            /* Black */

                            color: #1a1a1a;
                        }
                    }

                    p {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 150%;
                        letter-spacing: -0.025em;
                        color: #ffffff;
                        margin-bottom: 32px;

                        &.title {
                            font-style: normal;
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 150%;
                            letter-spacing: -0.025em;
                            color: #ffffff;
                        }
                    }

                    > .menu {
                        display: flex;
                        flex-direction: column;

                        a {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 150%;
                            letter-spacing: -0.025em;

                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }

    .creditos {
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #fff;
            padding-top: 30px;
            margin-top: 30px;
            @media (max-width: 991px) {
                flex-direction: column;
                align-items: flex-start;
                gap: 25px;
            }
            p {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: -0.025em;
                color: #ffffff;
                margin: 0;

                a {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 150%;
                    letter-spacing: -0.025em;
                    color: #ffffff;
                }
            }
        }
    }
}
