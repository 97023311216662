#header {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;
        button.toggleMenu {
            width: 40px;
            height: 40px;
            display: none;
            position: relative;
            z-index: 99999;
            align-items: center;
            justify-content: center;
            border-radius: 0;
            border: 0;
            background-color: var(--ion-color-primary);
            transition: 0.3s;
            img {
                width: 24px;
                height: 24px;
            }

            &.active {
                border-radius: 50%;
                img {
                    width: 16px;
                    height: 16px;
                }
            }

            @media (max-width: 991px) {
                display: flex;
            }
        }

        .menu {
            height: 100%;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 25px;

            a {
                display: flex;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                /* identical to box height, or 14px */

                text-align: right;
                letter-spacing: -0.025em;

                color: #404755;
                align-items: center;

                &:last-child {
                    background: var(--ion-color-primary);
                    display: flex;
                    padding: 10px 16px;
                    gap: 16px;
                    border-radius: 6px;
                }
            }

            @media (max-width: 991px) {
                position: fixed;
                z-index: 9999;
                top: 0;
                right: -400px;
                width: 300px;
                background-color: var(--ion-color-primary);
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 10vh;
                transition: 0.3s;
                box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);

                &.active {
                    right: 0;
                }
            }
        }
    }
}
